import Button from "react-bootstrap/Button";
import "./button.css";

export const Btn = (props) => {
    return (
        <Button
            type={props.type}
            onClick={props.onClick}
            className={props.btnStyle}
            disabled={props.disabled}
            name={props.name}
        >
            {props.text} {props.arrow ? <span className="gcicon-right"></span> : null}
        </Button>
    );
};

export default Btn;


import React, { useState } from "react";
import Styles from "./resourceItem.module.css";
import Btn from "../../components/Button/Button";
import Arrow from "../../assets/images/arrow.svg";

const ResourceItem = (props) => {

    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        if (selected === true) {
            return setSelected(null)
        }

        setSelected(true);
    }

    const ReplaceRenderHTML = (rawHTML) => {
        let output = rawHTML;
        let i = 0, x = 0;
        let replacement = '<br/>';
        const regex = /(\r\n|(?<!\r)\n|\r(?!\n))/g;
        let matches = output.matchAll(regex);
        for (const match of matches) {
            if (i > 0) {
                x = i * replacement.length;
                output = [output.slice(0, match.index + match[0].length + x), replacement, output.slice(match.index + match[0].length + x)].join('');
            } else {
                output = [output.slice(0, match.index + match[0].length), replacement, output.slice(match.index + match[0].length)].join('');
            }
            i += 1;
        }
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: output } });
    };

    return (
        <div className={Styles.resourceItemContainer}>
            <div className={Styles.resourceItemContent}>
                <h4>{props.category}</h4>
                <h3>{props.title}</h3>
                <h6>{props.resourceType}</h6>
                <p>{props.text}</p>
            </div>
            <div className={Styles.readMoreContainer}>
                <div className={Styles.readMore} onClick={() => handleClick()}>
                    <p>Read more</p>
                    <img className={selected !== (null) ? `${Styles.arrow} ${Styles.rotate}` : `${Styles.arrow}`} src={Arrow} alt={Arrow} />
                </div>
            </div>
            <div className={selected !== (null) ? `${Styles.readMoreContent} ${Styles.show}` : `${Styles.readMoreContent}`}>
                <hr></hr>
                <div>{ReplaceRenderHTML(props.readMore)}</div>
            </div>
            <div className={Styles.resourceItemCta}>
                {props.link &&
                    <a className={Styles.linkA} href={props.link} target="_blank" rel="noreferrer">
                        <Btn
                        text={"View site"}
                        btnStyle="btnActive"
                        />
                    </a>
                }
            </div>
        </div>
    );
}

export default ResourceItem;
// IDs have to match db ids until this is served from db
export const filterList = [
     {
         question: 'What are you looking for?',
         //name: 'support_type',
         name: 'One',
         type: "radio",
         id: 1,
         options: [
             {
                 info: "Business support",
                 value: 1,
                 id: 1,
             },
             {
                 info: "Funding",
                 value: 2,
                 id: 2,
             },
             {
                 info: "Information",
                 value: 89,
                 id: 89,
             }
         ],
     },
     {
         question: 'Where is your principal office location in Greater Manchester?',
         name: 'Two',
         type: "radio",
         id: 2,
         options: [
             {
                 info: "Bolton",
                 value: 4,
                 id: 4,
             },
             {
                 info: "Bury",
                 value: 5,
                 id: 5,
             },
             {
                 info: "Manchester",
                 value: 6,
                 id: 6,
             },
             {
                 info: "Oldham",
                 value: 7,
                 id: 7,
             },
             {
                 info: "Rochdale",
                 value: 8,
                 id: 8,
             },
             {
                 info: "Salford",
                 value: 9,
                 id: 9,
             },
             {
                 info: "Stockport",
                 value: 10,
                 id: 10,
             },
             {
                 info: "Tameside",
                 value: 11,
                 id: 11,
             },
             {
                 info: "Trafford",
                 value: 12,
                 id: 12,
             },
             {
                 info: "Wigan",
                 value: 13,
                 id: 13,
             },
         ],
     },

     {
         question: 'What type of business are you?',
         name: 'Three',
         type: "radio",
         id: 3,
         options: [
             {
                 info: "Self-employed/Partnership",
                 value: 14,
                 id: 14,
             },
             {
                 info: "Registered company",
                 value: 15,
                 id: 15,
             },
             {
                 info: "Third Sector Organisation",
                 value: 16,
                 id: 16,
             },
             {
                 info: "Pre-Start - unregistered",
                 value: 17,
                 id: 17,
             },
             {
                 info: "Pre-start - registered/incorporated",
                 value: 18,
                 id: 18,
             },
             {
                 info: "Government/Publicly Funded Education",
                 value: 19,
                 id: 19,
             },
         ],
     },
     {
         question: 'What sector is your business in?<br/><i>(tick all relevant sectors)<i/>',
         name: 'Four',
         type: "checkbox",
         id: 4,
         options: [
             {
                 info: "Agriculture, Forestry & Fishing",
                 value: 20,
                 id: 20,
             },
             {
                 info: "Business, Financial and Professional Services",
                 value: 21,
                 id: 21,
             },
             {
                 info: "Construction",
                 value: 22,
                 id: 22,
             },
             {
                 info: "Digital, Creative and Technology",
                 value: 23,
                 id: 23,
             },
             {
                 info: "Education",
                 value: 24,
                 id: 24,
             },
             {
                 info: "Engineering",
                 value: 25,
                 id: 25,
             },
             {
                 info: "Green Technologies and Services",
                 value: 26,
                 id: 26,
             },
             {
                 info: "Health and Social Care",
                 value: 27,
                 id: 27,
             },

             {
                 info: "Hospitality, Tourism and Leisure",
                 value: 28,
                 id: 28,
             },
             {
                 info: "Life Sciences",
                 value: 29,
                 id: 29,
             },
             {
                 info: "Logistics",
                 value: 30,
                 id: 30,
             },
             {
                 info: "Manufacturing",
                 value: 31,
                 id: 31,
             },
             {
                 info: "Mining and Quarrying",
                 value: 32,
                 id: 32,
             },
             {
                 info: "Public Administration and Defence",
                 value: 33,
                 id: 33,
             },
             {
                 info: "Retail and Wholesale",
                 value: 34,
                 id: 34,
             },
             {
                 info: "Service Activities",
                 value: 35,
                 id: 35,
             },
         ],
     },
     {
         question: 'What is the size of your business?',
         name: 'Five',
         type: "radio",
         id: 5,
         options: [
             {
                 info: "Self Employed (No staff)",
                 value: 36,
                 id: 36,
             },
             {
                 info: "0-9 employees",
                 value: 37,
                 id: 37,
             },
             {
                 info: "10-49 employees",
                 value: 38,
                 id: 38,
             },
             {
                 info: "50-249 employees",
                 value: 39,
                 id: 39,
             },
             {
                 info: "250+ employees",
                 value: 40,
                 id: 40,
             },
         ],
     },
     {
         question: 'What is the age of your business?',
         name: 'Six',
         type: "radio",
         id: 6,
         options: [
             {
                 info: "Pre-trading",
                 value: 41,
                 id: 41,
             },
             {
                 info: "Start-ups (0-3 years)",
                 value: 42,
                 id: 42,
             },
             {
                 info: "Established (3 years +)",
                 value: 43,
                 id: 43,
             },
         ],
     },
     {
         question: 'How much does your business turnover annually?',
         name: 'Seven',
         type: "radio",
         id: 7,
         options: [
             {
                 info: "Pre-trading",
                 value: 44,
                 id: 44,
             },
             {
                 info: "Less than &pound;50,000",
                 value: 45,
                 id: 45,
             },
             {
                 info: "&pound;50,000 - &pound;250,000",
                 value: 46,
                 id: 46,
             },
             {
                 info: "&pound;250,000 - &pound;1,000,000",
                 value: 47,
                 id: 47,
             },
             {
                 info: "&pound;1,000,000 - &pound;10,000,000",
                 value: 48,
                 id: 48,
             },
             {
                 info: "&pound;10,000,000 - &pound;50,000,000",
                 value: 49,
                 id: 49,
             },
             {
                 info: "&pound;50,000,000 +",
                 value: 50,
                 id: 50,
             },
         ],
     },
     {
         question: 'What are your businesses key priorities?<br/><i>(please tick your top 3 priorities.)</i>',
         name: 'Eight',
         type: "checkbox",
         id: 8,
         options: [
             {
                 info: "Starting a business",
                 value: 51,
                 id: 51,
             },
             {
                 info: "Self-employment",
                 value: 52,
                 id: 52,
             },
             {
                 info: "Business Strategy & Planning",
                 value: 53,
                 id: 53,
             },
             {
                 info: "Sales and marketing",
                 value: 54,
                 id: 54,
             },
             {
                 info: "Net zero",
                 value: 55,
                 id: 55,
             },
             {
                 info: "Leadership and management",
                 value: 56,
                 id: 56,
             },
             {
                 info: "Recruitment",
                 value: 57,
                 id: 57,
             },
             {
                 info: "Succession planning / exit strategy",
                 value: 58,
                 id: 58,
             },
             {
                 info: "Adopting digital technology / digital transformation",
                 value: 59,
                 id: 59,
             },
             {
                 info: "Supply chain development",
                 value: 60,
                 id: 60,
             },
             {
                 info: "Sustainability",
                 value: 61,
                 id: 61,
             },
             {
                 info: "Innovating a new product or service",
                 value: 62,
                 id: 62,
             },
             {
                 info: "Managing business finance",
                 value: 63,
                 id: 63,
             },
             //{
             //    info: "EU Exit support",
             //    value: 64,
             //    id: 64,
             //},
             {
                 info: "Training and development",
                 value: 65,
                 id: 65,
             },
             {
                 info: "Accessing international markets",
                 value: 66,
                 id: 66,
             },
             {
                 info: "Compliance & Regulation",
                 value: 84,
                 id: 84,
             },
             {
                 info: "Networking",
                 value: 85,
                 id: 85,
             },
         ],
     },
     {
         question: 'Which type of funding are you looking for?<br/><i>(tick all relevant types)</i>',
         name: 'Nine',
         type: "checkbox",
         id: 9,
         options: [
             {
                 info: "Debt (i.e. Loans, overdrafts, asset finance)",
                 value: '67',
                 id: '67',
             },
             {
                 info: "Equity",
                 value: '68',
                 id: '68',
             },
             {
                 info: "Grants (due to limited availability grants are rarely the only option within an overall funding round)",
                 value: '69',
                 id: '69',
             },
             {
                 info: "Tax relief & credits",
                 value: '88',
                 id: '88',
             },
         ],
    },
    {
        question: 'How much funding are you looking for?',
        name: 'Ten',
        type: "checkbox",
        id: 10,
        options: [
            {
                info: "Up to &pound;25,000",
                value: '70',
                id: '70',
            },
            {
                info: "&pound;25,000 - &pound;100,000",
                value: '71',
                id: '71',
            },
            {
                info: "&pound;100,000 - &pound;250,000",
                value: '72',
                id: '72',
            },
            {
                info: "&pound;250,000 - &pound;500,000",
                value: '73',
                id: '73',
            },
            {
                info: "&pound;500,000 - and over",
                value: '74',
                id: '74',
            }
        ]
    },
    {
        question: 'What is the purpose of this funding?',
        name: 'Eleven',
        type: "checkbox",
        id: 11,
        options: [
            {
                info: "Asset Purchase",
                value: '75',
                id: '75',
            },
            {
                info: "Growth Capital",
                value: '76',
                id: '76',
            },
            {
                info: "Property Purchase",
                value: '77',
                id: '77',
            },
            {
                info: "Property Development",
                value: '78',
                id: '78',
            },
            {
                info: "Refinance",
                value: '79',
                id: '79',
            },
            {
                info: "Start-up Funding",
                value: '80',
                id: '80',
            },
            {
                info: "Working Capital",
                value: '81',
                id: '81',
            },
            //{
            //    info: "Equity Funding",
            //    value: '82',
            //    id: '82',
            //},
            {
                info: "Research and Development",
                value: '83',
                id: '83',
            },
            {
                info: "Import and Export",
                value: '86',
                id: '86',
            },
            {
                info: "Vouchers",
                value: '87',
                id: '87',
            },
        ]
    },
 ]
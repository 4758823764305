import React, { useState, createContext, useContext} from "react";

import { loginUser } from "./utils";

const authContext = createContext();

const userLogin = async (username, password) => {
    let token = getToken();
    if (!token) {
        token = await loginUser({ username, password });
        setToken(token);
    }
    return token;
}

function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
}

function useAuth() {
    const [authed, setAuthed] = useState(getToken()?true:false);

    return {
        authed,
        login(username, password) {
            return new Promise((res, rej) => {
                userLogin(username, password).then((_token) => {
                    if (_token && _token !== '') {
                        setAuthed(true);
                        res(true);
                    } else {
                        setAuthed(false);
                        rej("Invalid")
                    }
                })
            });           
        },
        logout() {
            return new Promise((res) => {
                setAuthed(false);
                res();
            });
        },
    };
}

export function AuthProvider({ children }) {
    const auth = useAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
    return useContext(authContext);
}
import Alert from 'react-bootstrap/Alert';

export const Alerts = (({ text, header, variant, func }) => {
    return (
        <>
            <Alert variant={variant} onClose={() => func(false)} dismissible>
                <Alert.Heading>{header}</Alert.Heading>
                {text? text : '' }
            </Alert>
        </>
    )
})
import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import BGHLogo from "../../assets/images/GM_BGH_Master_Logo_WO_RGB.png";
import GMCAlogo from "../../assets/images/gmca-logo.svg";
import Twitter from "../../assets/images/social-icons/twitterLogo.svg";
import Facebook from "../../assets/images/social-icons/facebookLogo.svg";
import LinkedIn from "../../assets/images/social-icons/linkedInLogo.svg";
import Youtube from "../../assets/images/social-icons/youtubeLogo.svg";


const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <div className={styles.footerContainer}>
            <section>

                <div className={styles.sectionFooterContainer}>
                    <div className={`${styles.footerContent1}`}>
                        <h3 style={{ color: 'var(--teal)' }}>Business Growth Hub</h3>
                        <p style={{ color: 'white' }}>
                            Lee House<br></br>
                            90 Great Bridgewater Street<br></br>
                            Manchester<br></br>
                            M1 5JW<br></br>
                            <br></br>
                            <small style={{ color: 'white' }}>
                                Registered in England number: 8132524<br></br>
                                VAT Registration Number: 727102071
                            </small>
                        </p>
                    </div>
                    <div className={`${styles.footerContent2}`}>
                        <h3 style={{ color: 'var(--teal)' }}>Contact</h3>
                        <p>Tel: <a className={styles.hoverStyle} href="tel:0161 359 3050" target="_blank" rel="noreferrer">0161 359 3050</a></p>
                        <p>Email: <a className={styles.hoverStyle} href="mailto:bgh@growthco.uk" target="_blank" rel="noreferrer">bgh@growthco.uk</a></p>
                    </div>
                    <div className={`${styles.footerContent3}`}>
                        <h3 style={{ color: 'var(--teal)' }}>Social Media</h3>

                        <a href="http://twitter.com/#!/BizGrowthHub" target="_blank" rel="noreferrer">
                            <div className={styles.socialContainer}>
                                <img className={styles.socialIcon} src={Twitter} alt="Twitter logo"></img>
                                <p className={styles.hoverStyle}>Twitter</p>
                            </div>
                        </a>

                        <a href="https://www.facebook.com/gcbusinessgrowthhub" target="_blank" rel="noreferrer">
                            <div className={styles.socialContainer}>
                                <img className={styles.socialIcon} src={Facebook} alt="Facebook logo"></img>
                                <p className={styles.hoverStyle}>Facebook</p>
                            </div>
                        </a>

                        <a href="https://www.linkedin.com/company/gcbusinessgrowthhub/" target="_blank" rel="noreferrer">
                            <div className={styles.socialContainer}>
                                <img className={styles.socialIcon} src={LinkedIn} alt="LinkedIn logo"></img>
                                <p className={styles.hoverStyle}>LinkedIn</p>
                            </div>
                        </a>

                        <a href="http://www.youtube.com/user/GMBusinessGrowthHub/videos" target="_blank" rel="noreferrer">
                            <div className={styles.socialContainer}>
                                <img className={styles.socialIcon} src={Youtube} alt="Youtube logo"></img>
                                <p className={styles.hoverStyle}>Youtube</p>
                            </div>
                        </a>

                    </div>
                    <div className={`${styles.footerContent4}`}>
                        <img className={styles.logo} src={BGHLogo} alt="Bussiness Growth Hub" />
                        <img className={styles.gmcaLogo} src={GMCAlogo} alt="Bussiness Growth Hub" />
                    </div>

                </div>
                <div className={styles.footerBottomContainer}>
                    <div className={styles.footerLinkContainer}>
                        <div>
                            <a href="https://www.businessgrowthhub.com/privacy-policy" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Privacy Policy</small></p>
                            </a>
                        </div>
                        <div>
                            <Link to="/cookie-policy" className={styles.link}>
                                <p><small className={styles.hoverStyle}>Cookie Policy</small></p>
                            </Link>
                        </div>
                        <div>
                            <a href="https://www.businessgrowthhub.com/environmental-policy" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Environmental Policy</small></p>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.businessgrowthhub.com/sustainability-policy" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Sustainability Policy</small></p>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.businessgrowthhub.com/social-value-policy" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Social Value Policy</small></p>
                            </a>
                        </div>
                        <div>
                            <Link as={Link} to="/accessibility-statement">
                                <p><small className={styles.hoverStyle}>Accessibility Statement</small></p>
                            </Link>
                        </div>
                        <div>
                            <a href="https://www.growthco.uk/about-us/policies/modern-slavery-and-human-trafficking-statement/" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Modern Slavery Statement</small></p>
                            </a>
                        </div>
                        <div>
                            <Link as={Link} to="/terms-and-conditions">
                                <p><small className={styles.hoverStyle}>Terms of use</small></p>
                            </Link>
                        </div>
                        <div>
                            <a href="https://www.businessgrowthhub.com/contact-us" target="_blank" rel="noreferrer">
                                <p><small className={styles.hoverStyle}>Contact</small></p>
                            </a>
                        </div>

                    </div>
                    <div style={{ textAlign: "left", fontSize: "12px" }}>
                        <p><small style={{ color: "lightgray" }}>&copy; GM Business Support Ltd {year}</small></p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;
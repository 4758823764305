import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Btn from "../../components/Button/Button";
import { postProfileData } from "../../utils";

import Styles from "./sendModal.module.css";
import './sendModal.css';

export const SendModal = (props) => {

    const [showButton, setShowbutton] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [formsubmitted, setFormsubmitted] = useState(true);
    const [disable, setDisable] = useState(false);
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(true);

    const handleClose = () => {
        props.handleClose();
        clearData();
    };

    const clearData = () => {
        setFields({});
        setErrors({});
        setDisable(false);
        setShowbutton(true);
        setValidated(false);
        setCheckedEmail(true);
        const timer = setTimeout(() => setShowForm(true), 1000);
        return () => clearTimeout(timer);
    }

    const postData = async (profile) => {
        setDisable(true);
        const data = await postProfileData(profile);
        return data;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (handleValidation()) {

            const Answers = props.Answers;
            const ResourceIds = props.ResourceIds;
            const profile = {
                CompanyName: fields.CompanyName,
                FirstName: fields.FirstName,
                LastName: fields.LastName,
                EmailAddress: fields.EmailAddress,
                PhoneNumber: fields.PhoneNumber,
                Accept: fields.Accept,
                Email: fields.Email ? true : false,
                SMS: fields.SMS ? true : false,
                Phone: fields.Phone ? true : false,
                Answers,
                ResourceIds,
                SendEmail: checkedEmail,
                Postcode:fields.Postcode
            }

            postData(profile)
                .then((res) => { setFormsubmitted(res); setShowbutton(false); setShowForm(false) })
        }
    };

    const handleValidation = () => {
        let formIsValid = true;
        let nameValid = true, emailAddressValid = true, companyValid = true, acceptValid = true, contactValid = true, firstnameValid = true, lastnameValid = true, postcodeValid = true; 

        //Company
        companyValid = validateField("CompanyName", fields.CompanyName);
        //Email
        emailAddressValid = validateField("EmailAddress", fields.EmailAddress);
        //phone
        contactValid = validateField("PhoneNumber", fields.PhoneNumber);
        //1stname
        firstnameValid = validateField("FirstName", fields.FirstName);
        //1astname
        lastnameValid = validateField("LastName", fields.LastName);
        //postcode
        postcodeValid = validateField("Postcode", fields.Postcode);

        //accept
        if (fields.Accept === undefined) {
            setFields((values) => ({ ...values, 'Accept': false }));
            acceptValid = false;
        } else acceptValid = fields.Accept;

        formIsValid = (nameValid && emailAddressValid && companyValid && acceptValid && contactValid && firstnameValid && lastnameValid && postcodeValid);
        setValidated(formIsValid);
        return formIsValid;
    };

    const handleChange = (field, e) => {
        const value = e.target.value;

        if (field === 'Accept' || field === 'Email' || field === 'SMS' || field === 'Phone' || field === 'SendEmail') {
            setFields((values) => ({ ...values, [field]: e.target.checked }));
            validateField(field, e.target.checked);
        }
        else {
            setErrors((errs) => ({ ...errs, [field]: '' }));
            setFields((values) => ({ ...values, [field]: value }));
            validateField(field, value);

        }
    }

    const validateField = (field, val) => {
        let isValid = true, errMes = '';

        switch (field) {
            case 'EmailAddress':
                if (!val || val.length === 0) { errMes = 'Please enter an email'; isValid = false; }
                else {
                    isValid = val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                    errMes = isValid ? '' : 'Please enter a valid email';
                }
                break;
            case 'FirstName':
                if (!val || val.length === 0) { errMes = 'Please enter your first name(s)'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{2,100}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid first name';
                }
                break;
            case 'LasttName':
                if (!val || val.length === 0) { errMes = 'Please enter your last name'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{2,100}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid last name';
                }
                break;
            case 'CompanyName':
                if (!val || val.length === 0) { errMes = 'Please enter a company name'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{3,100}$)[a-zA-Z0-9]+(?:[-'\s][a-zA-Z0-9]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid company name';
                }
                break;
            case 'PhoneNumber':
                if (!val || val.length === 0) { errMes = ''; isValid = true; }
                else {
                    //isValid = val.replace(/\D/g, '').match(/^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/);
                    //isValid = val.replace(/\D/g, '').match(/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/);
                    isValid = val.replace(/\D/g, '').match(/^\d{10,15}/);
                    errMes = isValid ? '' : ' Please enter valid contact number';
                }
                break;
            case 'Postcode':
                if (!val || val.length === 0) { errMes = 'Please enter a postcode'; isValid = false; }
                else {
                    isValid = !!val.match(/^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/);
                    errMes = isValid ? '' : ' Please enter valid postcode';
                }
                break;
            case 'Accept':
                isValid = val;
                break;
            default:
                break;
        }
        if (errMes !== '') setErrors((errs) => ({ ...errs, [field]: errMes }));

        return isValid;
    }

    const renderMessage = () => {
        let mes = {};
        if (!formsubmitted) {
            mes = <div>Submission not succesfull Please report this to <a href="mailto:info@businesssupportfinder.com">info@businesssupportfinder.com</a> or try again later</div>;
        } else {
             mes = <div>Data successfully submited, Thank you. Your download should start automatically. Please check your default downloads folder if download not obvious.</div>
        }
        return mes;
    }

    const renderForm = () => {
        return(
            <Form noValidate validated={validated}>
                <div className={Styles.inputContainer}>
                    <Form.Group className="mb-1" controlId="enquiryForm.FirstNameInput">
                        <Form.Control placeholder="Forename"
                            onChange={(e) => { handleChange("FirstName", e) }}
                            value={fields["FirstName"] || ''}
                            isValid={!errors.FirstName && !!fields.FirstName && fields.FirstName !== ''}
                            isInvalid={!!errors.FirstName && errors.FirstName !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["FirstName"]}
                        </Form.Control.Feedback>
                    </Form.Group> 
                    <Form.Group className="mb-1" controlId="enquiryForm.LastNameInput">
                        <Form.Control placeholder="Surname"
                            onChange={(e) => { handleChange("LastName", e) }}
                            value={fields["LastName"] || ''}
                            isValid={!errors.LastName && !!fields.LastName && fields.LastName !== ''}
                            isInvalid={!!errors.LastName && errors.LastName !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["FirstName"]}
                        </Form.Control.Feedback>
                    </Form.Group> 
                    <Form.Group className="mb-1" controlId="enquiryForm.CompanyInput">
                        <Form.Control placeholder="Company Name"
                            onChange={(e) => { handleChange("CompanyName", e) }}
                            value={fields["CompanyName"] || ''}
                            isValid={!errors.CompanyName && !!fields.CompanyName && fields.CompanyName !== ''}
                            isInvalid={!!errors.CompanyName && errors.CompanyName !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["CompanyName"]}
                        </Form.Control.Feedback>
                    </Form.Group>      
                    <Form.Group className="mb-1" controlId="enquiryForm.EmailInput">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            onChange={(e) => { handleChange("EmailAddress", e) }}
                            value={fields.EmailAddress || ''}
                            isValid={!errors.EmailAddress && !!fields.EmailAddress && fields.EmailAddress !== ''}
                            isInvalid={!!errors.EmailAddress && errors.EmailAddress !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["EmailAddress"]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="enquiryForm.ContactInput">
                        <Form.Control
                            type="phone"
                            placeholder="Telephone number"
                            onChange={(e) => { handleChange("PhoneNumber", e) }}
                            value={fields.PhoneNumber || ''}
                            isValid={!errors.PhoneNumber && !!fields.PhoneNumber && fields.PhoneNumber !== ''}
                            isInvalid={!!errors.PhoneNumber && errors.PhoneNumber !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["PhoneNumber"]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="enquiryForm.Postcode">
                        <Form.Control
                            type="text"
                            placeholder="Postcode e.g. M1 5JW"
                            onChange={(e) => { handleChange("Postcode", e) }}
                            value={fields.Postcode || ''}
                            isValid={!errors.Postcode && !!fields.Postcode && fields.Postcode !== ''}
                            isInvalid={!!errors.Postcode && errors.Postcode !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["Postcode"]}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div>
                        <p className={Styles.disclaimerText}>Please unselect this if you do not wish to recieve an email with the resources in PDF format, to be sent</p>
                        <Form.Group>
                            <Form.Check
                                label={<label>Send Resources to my email account</label>}
                                type="checkbox"
                                defaultChecked={checkedEmail}
                                onChange={(e) => { setCheckedEmail((state) => !state) }}
                            />
                        </Form.Group>
                        
                    </div>
                </div>

                <p className={Styles.disclaimerText}>GM Business Growth Hub and the Growth Company will use the information provided to keep you informed of news, services, insights and events relevant to you. Please select from the following options to confirm all the ways you would like us to keep in touch:</p>
                
                <div className={Styles.checkBoxContainer}>
                    <Form.Group className="">
                        <Form.Check
                            label={<label>Email</label>}
                            type="checkbox"
                            onChange={(e) => { handleChange("Email", e) }}
                        />
                    </Form.Group>
                    <Form.Group className="">
                        <Form.Check
                            label={<label>Phone</label>}
                            type="checkbox"
                            onChange={(e) => { handleChange("Phone", e) }}
                        />
                    </Form.Group>
                    <Form.Group className="">
                        <Form.Check
                            label={<label>SMS</label>}
                            type="checkbox"
                            onChange={(e) => { handleChange("SMS", e) }}
                        />
                    </Form.Group>
                </div>

                <div className="" >
                    <Form.Group className="">
                        <Form.Check
                            required
                            label={<label>I have read and agree to the&#xa0;<a className={Styles.linkA} href="https://www.businesssupportfinder.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>&#xa0;and&#xa0;<a className={Styles.linkA} href="https://www.businessgrowthhub.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></label>}
                            type="checkbox"
                            onChange={(e) => { handleChange("Accept", e) }}
                            isValid={fields.Accept === true}
                            isInvalid={fields.Accept === false}
                            feedback="You must agree before submission."
                            feedbackType="invalid"
                        />
                    </Form.Group>
                </div>
                <p className={Styles.privacyText}>
                    The use of your personal information is governed by our <a className={Styles.linkA} target="_blank" rel="noreferrer" href="https://www.businessgrowthhub.com/privacy-policy">Group Privacy Policy</a>.
                    GM Business Growth Hub is a trading name of GM Business Support Limited, part of the Growth Company group. More information about the different services provided by the Growth Company and the organisations which are part of our group can be found <a className={Styles.linkA} target="_blank" rel="noreferrer" href="http://www.growthco.uk/about-us/company-information/">here</a>.

                    {/*The use of your personal information is governed by our Group Privacy Policy, which can be found at <a className={Styles.linkA} target="_blank" rel="noreferrer" href="https://www.businessgrowthhub.com/privacy-policy">www.businessgrowthhub.com/privacy-policy</a>*/}
                    {/*, or, where support is provided through EU funding, it is also, jointly governed by the Ministry of Housing, Communities and Local Government's Privacy Policy,*/}
                    {/*which can be found here. However, due to the nature of our mixed portfolio of services and funding streams, in a small number of cases other privacy policies may apply,*/}
                    {/*and your Growth Company contact will be able to clarify this and provide you with a copy of the relevant policy(s) on request. GM Business Growth Hub is a trading name of GM Business Support Limited,*/}
                    {/*part of the Growth Company group. More information about the different services provided by the Growth Company and the organisations which are part of our group can be found at: <a className={Styles.linkA} target="_blank" rel="noreferrer" href="http://www.growthco.uk/about-us/company-information/">www.growthco.uk/about-us/company-information/</a>*/}
                </p>
            </Form>
        )
    }

    return (
        <Modal show={props.show} onHide={handleClose} centered scrollable={true} >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!showForm ? renderMessage() : renderForm()}
            </Modal.Body>
            {showButton &&
                <Modal.Footer>
                    <Btn
                        text={"Submit"}
                        btnStyle="btnActive"
                        disabled={disable}
                        onClick={handleSubmit} 
                    />
                </Modal.Footer>
            }
        </Modal>
    );
}
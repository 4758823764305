import { Link } from "react-router-dom";
import styles from "./Terms.module.css"

export const Terms = () => {
    return (
        <>
            
            <div className={styles.termsContainer}>
                <div className={styles.linkContainer}>
                    <Link to="/" state={{ from: "terms" }} className={styles.goBackDesktop}>
                        <span className="gcicon-left"></span>Back
                    </Link>
                </div>
                <h2>Terms of use</h2>

                <p>IMPORTANT - PLEASE READ CAREFULLY BEFORE USING THIS WEBSITE</p>

                <p>The terms and conditions of use set out below apply to the Website owned, operated and controlled by
                    Us, located on the World Wide Web at https://www.businesssupportfinder.com ("the Website"). By using the
                    Website, You are deemed to have full knowledge of the following terms and conditions and to accept them.
                    If You do not agree to be bound by the following terms and conditions, please do not use the
                    Website.</p>


                <h3>Alteration to the Terms and Conditions</h3>

                <p>We may, at any time change, modify, add to or remove part or all of these Terms and Conditions. You
                    should therefore check these Terms and Conditions periodically to see if they have changed. Your
                    continued use of this Website following any change in the Terms and Conditions will be deemed to
                    constitute acceptance by You of those changes.</p>

                <h3>Copyright</h3>

                <p>All material accessible on this Website is protected by copyright. You may view Website pages on screen
                    and You may print or download extracts for Your own personal use or for use by others within Your
                    organisation.</p>

                <p>You may supply a copy of any such extract to any third party provided that:

                    <ul><li>the extract is for their own personal use;</li>

                        <li>the extract is not supplied as part of or incorporated in another work, Website or publication;
                        </li>

                        <li>the extract is not supplied either directly or indirectly in return for commercial gain; and</li>

                        <li>the third party is made aware that the source of the copy is this Website and that these Terms and
                            Conditions apply equally to them.
                        </li>

                        <li>No part of this Website may be reproduced, transmitted to, or stored on any other Website or in
                            any
                            other form of electronic medium without Our express written consent.
                        </li></ul></p>

                <h3>Continuity of Service</h3>

                <p>We will use Our reasonable endeavours to provide You with a prompt and continuing service. We do not
                    however warrant that the service We provide will continue uninterrupted or without delay or that it will
                    remain unchanged. In particular We reserve the right to bring the Website down as and when We consider
                    the same to be necessary or desirable for the purposes of maintenance.</p>

                <h3>Indemnity</h3>

                <p>In using the Website, You indemnify Us against any actions, claims, demands or other proceedings
                    brought by any third party against Us as a consequence of Your use of the site.</p>


                <h3>Quality of Service</h3>

                <p>Whilst We will use its reasonable endeavours to ensure that the information contained in the Website is
                    correct and reliable, no warranty, either express or implied, is given as to the accuracy or
                    completeness of that information.</p>

                <h3>Links to External Sites</h3>

                <p>The Website contains hypertext links to a number of Websites owned, operated and controlled by third
                    parties. We have no control over or proprietary interest in any of these Websites and, as such, makes no
                    warranties with regard to the quality, security, accuracy or any other aspect of such sites, and
                    excludes any and all liability arising from use of the same.</p>

                <h3>Third Party Contributors</h3>

                <p>You acknowledge that many parts of the service provided on this Website are provided by third party
                    service providers ("TSPs") and not by Us. You agree that We shall bear no responsibility to You in
                    respect of any aspect of any third party services or contributions provided by TSP's and that Your only
                    remedy for failure to provide such services is a remedy against the relevant TSP.</p>

                <p>Where appropriate the provision of any third party services to You by any TSP shall be provided to You
                    on the terms and conditions of the relevant TSP. It is Your responsibility to make yourself aware of
                    such terms and conditions.</p>

                <p>Acceptance by You of any services provided by an TSP may form a contract between You and the TSP
                    directly. It is Your responsibility to check whether acceptance of such services will be construed as
                    confirming Your total and unequivocal acceptance of the TSP's terms and conditions of service.
                </p>

                <h3>Acceptable Use Policy</h3>

                <p>In using the Website You are deemed to have read our Acceptable Use Policy, covered in point 1-4 below,
                    which contains the rules governing use of the Website The Policy forms part of these Terms and
                    Conditions.</p>

                <h4>1. General</h4>

                <p>This Policy provides only general guidance with regard to the use of the Website by You.</p>

                <h4>2. Copyright</h4>

                <p>(a) If You place material, including software, on the Website You will automatically grant to Us the
                    right, at no cost, to copy, adapt, edit, publish, distribute, translate and otherwise use all such
                    material and to grant to its other customers the right to print copies of the material for their own
                    use. Subject to this grant, You will retain all rights in the material.</p>

                <p>(b) You may not place copyright material on the Website without the written permission of the owner of
                    the copyright or of some person authorised by the owner to give such consent. You must, if requested to
                    do so by Us, provide written proof of such consent.
                </p>

                <h4>3. Content</h4>

                <p>(a) When posting any advertisement or other commercial solicitation or material to any special interest
                    group or site it is Your obligation to comply with all applicable laws. In particular, the advertisement
                    or commercial solicitation must comply with all relevant legislation statutes, regulations and codes in
                    any relevant jurisdiction.</p>

                <p>(b) Posting private or other confidential material to any special interest group is prohibited.</p>

                <p>(c) Impersonating another business or person or otherwise falsifying Your business or other name in any
                    post to any group is prohibited.
                </p>

                <p>(d) Any communication or posting which may be perceived or construed by Us to be harassment of a third
                    party by e-mail is prohibited as is the use of the Website for sending computer viruses or the like.
                </p>

                <p>(e) You must not publish on the Website any material which infringes the rights of any person
                    (including, without limitation, their copyright, trademark, confidential information or other
                    intellectual property rights) or which is, in Our sole and absolute opinion, abusive, blasphemous,
                    obscene, pornographic, defamatory, vitriolic, discriminatory, menacing in character, illegal or
                    deliberately inflammatory. We considers the publication of such materials and other inappropriate
                    materials to be severe abuses of the Website
                </p>

                <p>(f) You must not use the Website to perform or to solicit the performance of any illegal activity or
                    any activity that is contrary to generally accepted convention or is intended to solicit membership or
                    sale of any services competing with the Website
                </p>

                <p>(g) We reserve the right in its sole and absolute discretion to delete any material on the Website
                    without prior notice whether or not it violates any of the standards set out in this Policy.
                </p>

                <h4>4. Consequences of Ignoring the Policy</h4>

                <p>(a) If We in Our sole discretion consider that You are in breach of any of this Policy then We may take
                    such action as We, in Our sole discretion, considers appropriate. This may include (but shall not be
                    limited to), the editing or deletion of any material appearing on the Website or the termination of Your
                    rights of access to any service. You will not be entitled to any credit or compensation for any loss of
                    access to any service which results from any such action taken by Us under this Policy.</p>

                <p>(b) You accept that We give no undertaking to You that We will take any action to ensure compliance
                    with the Policy by any other customer and that We are entitled to take such action as We consider
                    appropriate under the Policy or to refrain from doing so at Our absolute discretion.</p>

                <p>(c) Whilst We do not have any obligation to monitor the Website We reserve the right to prohibit
                    activities which in Our absolute judgement may cause harm of any kind to other customers, third parties,
                    the Website or Us.
                </p>

                <h3>Your Responsibilities</h3>

                <p>You must ensure that at all times You observe these Terms and Conditions and the Acceptable Use Policy.
                    You may, from time to time, when you are using the Website, be asked to provide or be provided with a
                    password to enable You to access certain services. You must, at all times keep your password private and
                    confidential and you may not permit third parties to use Your password.</p>

                <h3>Queries</h3>

                <p>While every effort is made to ensure that any enquiry You submit is dealt with promptly, We cannot
                    guarantee that Your query will always be dealt with within the time-scale You require.</p>

                <p>If Your enquiry is submitted to a TSP linked to the Website and operating through this Website then Our
                    staff will not necessarily read or see Your enquiry. We cannot guarantee complete confidentiality and
                    recommend that You do not include sensitive information, or information which could be classed as a
                    trade secret, via the Internet.</p>

                <h3>Online Transactions</h3>

                <p>Transaction payments for Services offered through this website are with Business Support Solutions.</p>

                <p>Business Support Solutions is a trading name of ChamberLink Limited</p>

                <p>Online bookings will be processed through Sagepay and the transaction will be between You and Business
                    Support Solutions on behalf of Skills Solution Limited, unless otherwise stated.</p>

                <p>Where a refund is available, any payment made using a credit or debit card through the Sagepay system
                    will be refunded back onto the same card.</p>

                <h3>Booking, Cancellation and Transfer Policy - Workshops, events and programmes</h3>

                <p>Bookings will only be confirmed on receipt of a signed booking form or internet booking</p>

                <p>All cancellations and transfers must be e-mailed to bgh@growthco.uk in writing.</p>

                <h4>Cancellation and transfer fees are as follows:-</h4>

                <p>
                    <ul>
                        <li>5 or more working days notice = Can transfer to another workshop, event or programme up to the
                            same monetary value free of charge
                        </li>

                        <li>3 - 4 working days notice = Full payment required or can transfer to another workshop, event or
                            programme to the same monetary value for a fee of &pound;100.00
                        </li>

                        <li>1 - 2 working days notice = Full fees remain payable</li>
                    </ul>
                </p>

                <p>If a booking is not cancelled or transferred and the delegate does not attend, full tuition fees remain
                    payable.
                </p>

                <p>One transfer can be made per booking and confirmation of the new course must be made to the Business
                    Growth Hub bgh@growthco.uk within one week. If notification is not received within one week, full
                    tuition fees will remain payable.
                </p>

                <p>Substitute delegates can be made at any time without extra charge.</p>

                <p>We reserve the right to change the workshop, event or programme, presenter, venue and alter or cancel
                    published dates without liability. Provisional bookings cannot be accepted and bookings will be
                    confirmed on receipt of the signed booking form.
                </p>

                <h3>Booking, Cancellation and Transfer Policy - Events</h3>

                <p>Bookings will only be confirmed on receipt of a signed booking form with payment or internet booking
                    with payment.</p>

                <p>Full refunds/transfers will only be given if received within five working days of the event. All
                    cancellations and transfers must be emailed to bgh@growthco.uk</p>

                <p>If a booking is not cancelled or transferred and the delegate does not attend, full event fees remain
                    payable.
                </p>

                <p>We reserve the right to change the workshop, event or programme, presenter, venue and alter or cancel
                    published dates without liability</p>

                <h3>Booking, Cancellation and Transfer Policy - Business Advice</h3>

                <p>In the event of a customer supplying incorrect information relating to a company when ordering credit
                    reports, no refunds will be issued by Us.</p>

                <p>In the event of a customer supplying incorrect of insufficient information relating to mailing lists,
                    no refunds will be issued by Us.</p>

                <p>For all other services provided on the website, refunds will be given at Our discretion.</p>

                <h3>User Generated Content</h3>

                <p>The following terms apply when contributing content to the Network area of the Website, called The Hub.
                    You should not use this service unless you agree to these terms.</p>

                <p>We will not share your personal details with other members of the Network without your express consent.
                    You may choose to allow other members to see your name and/or email address when you join the Network,
                    and you may change your settings at any time under the Profile section.</p>

                <p>You take full responsibility for the content you add to The Hub.</p>

                <p>We may edit or delete any content you provide for publication on the Network at our discretion.</p>

                <p>You will not use The Hub in any way that is illegal or likely to encourage unlawful behaviour. You must
                    not add content that is defamatory, obscene, offensive, or infringes the copyright, trade marks or any
                    other intellectual property rights of another person or business.</p>

                <p>You will not add content that is likely to damage the reputation of GM Business Growth Hub or any part
                    of The Growth Company (a list of these companies can be found at:
                    http://www.growthco.uk/about-us/company-information/)</p>

                <p>The Hub must not be used as an advertising platform for your own or your employer's business.</p>

                <p>You will not try to access any confidential information (including usernames and passwords) of any
                    other person.
                </p>

                <p>We reserve the right to use the content you contribute to The Hub on other parts of the GM Business
                    Growth Hub website and in marketing materials and other print and digital channels.</p>

                <h3>Disclaimer</h3>

                <p>We shall not be liable for any claims or losses which are due to circumstances beyond Our reasonable
                    control. We shall not be liable for any claims or losses to the extent that these relate to profits,
                    indirect or consequential losses.</p>

                <p>This disclaimer does not affect Your statutory rights (if any) and no part of these terms and
                    conditions seeks to avoid liability for fraud, fraudulent misrepresentation or for negligence causing
                    personal injury or death.</p>

                <h3>General</h3>

                <p>These Terms and Conditions are governed by and shall be construed in accordance with English law.</p>

                <h3>Feedback</h3>

                <p>We hope that You will find our Website informative and easy to use. We should be grateful to receive
                    any comments or queries that You have concerning our site, or any suggestions as to how it could be
                    improved. Please e-mail us at <a href="mailto:bgh@growthco.uk">bgh@growthco.uk</a></p>


                <p>See also : <a href="https://www.businessgrowthhub.com/privacy-policy">Privacy Policy</a></p>

                <p>GM Business Growth Hub is a trading name of GM Business Support Limited. Registered in England. Company
                    Registration No. 08132524.
                    Registered Office: Lee House, 90 Great Bridgewater Street, Manchester, M1 5JW
                    VAT Registration number: 727102071</p>
            </div>
        </>
    );
};

import styles from "./Cookies.module.css"
import { useScript } from "../../utils/useScript";
import { Link } from "react-router-dom";

export const Cookies = () => {
    //const history = useHistory();
    //const navigateToHome = () => { history.push({ pathname: "/", }) }
    const { Cookie } = useScript('https://consent.cookiebot.com/0112773b-9537-4559-a417-6e6e7c55f47f/cd.js', 'CookieDeclaration', 'CookieDeclaration')
    return (
        <>

            <div className={styles.cookiesContainer}>
                <div className={styles.linkContainer}>
                    <Link to="/" state={{ from: "cookies" }} className={styles.goBackDesktop}>
                        <span className="gcicon-left"></span>Back
                    </Link>
                </div>
                <h2 className={styles.cookiesTitle}>Cookie Policy</h2>
                <div id="CookieDeclaration"></div>
                    {Cookie}
                {/*<script id="CookieDeclaration" src=https://consent.cookiebot.com/0112773b-9537-4559-a417-6e6e7c55f47f/cd.js type="text/javascript" async></script>*/}
            </div>
        </>
    );
};

import { Link } from "react-router-dom";
import Btn from "../components/Button/Button";
export function getParsedDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var _dt = new Date(date);
    return _dt.getDate() + ' ' + months[_dt.getMonth()] + ' ' + _dt.getFullYear() + ' ' + _dt.getHours() + ':' + _dt.getMinutes();
}

export function getParsedDateFull(date) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var _dt = new Date(date);
    return  days[_dt.getDay()] + ', ' + _dt.getDate() + ' ' + months[_dt.getMonth()] + ' ' + _dt.getFullYear() + ' ' + _dt.getHours() + ':' + _dt.getMinutes();
}

export const filterItemsCategories = (filter, original, latest) => {

    let filterItems = [...original];
    if (filter === 'Latest') {
        filterItems = latest;
    } else {
        let duplicateDisplayItems = [...original];
        let filteredDisplayItems = duplicateDisplayItems.filter((item) => item.category === filter);
        filterItems = filteredDisplayItems;
    }
    return filterItems;
}

function renderBulletList(bullet) {
    if (bullet && bullet !== '') return <li>{bullet}</li>
}
export function renderBullets(data) {
    if (data.bulletTitle && data.bulletTitle !== '') {
        return (
            <>
                <h3>{data.bulletTitle}</h3>
                <ul>
                    {renderBulletList(data.bullet1)}
                    {renderBulletList(data.bullet2)}
                    {renderBulletList(data.bullet3)}
                    {renderBulletList(data.bullet4)}
                    {renderBulletList(data.bullet5)}
                    {renderBulletList(data.bullet6)}
                </ul>
            </>
        )
    }
}
export function renderButton(data) {
    if (data.button && data.button !== '') {
        let idx = data.buttonUrl.indexOf('http');
        let pdfidx = data.buttonUrl.indexOf('.pdf');
        if (idx === -1 && pdfidx === -1) {
            return (
                <Link to={data.buttonUrl} >
                    <Btn text={data.button} color="Black" />
                </Link>
            )
        } else {
            return (
                <a href={data.buttonUrl} target="_blank" rel="noopener noreferrer">
                    <Btn text={data.button} color="Black" />
                </a>
            )
        }
       
    }
}

export const populateWeatherData = async () => {
    try {
        const url = process.env.REACT_APP_BKF_API_BASEURL + "weather";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
}

export const postQuestionData = async (questionsObject) => {
    let sent = true;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "resources", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(questionsObject),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            sent = data;
        }).catch((error) => {
            sent = false;
        });

        return sent;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const postProfileData = async (profileObject) => {
    let sent = true;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "resourcespdf", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(profileObject),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.blob();
        }).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url
            link.setAttribute('download', `${profileObject.CompanyName} Resources.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch((error) => {
            sent = false;
        });

        return sent;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};


export const postEnquiryEmailData = async (emailObject) => {
    let sent = true;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "enquiries", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(emailObject),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            sent = data;
        }).catch((error) => {
            sent = false;
        });

        return sent;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const populateResorcesAll = async () => {
    try {
        const url = process.env.REACT_APP_BKF_API_BASEURL + "resources";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const populateResourcesSingle = async (id) => {
    try {
        const url = process.env.REACT_APP_BKF_API_BASEURL + "resources/" + id;
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const updateResource = async (resource) => {
    let id = 0;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "resources", {
            method: "PUT",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(resource),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            id = data;
        }).catch((error) => {
            id = 0;
        });

        return id;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const postSearchResource = async (term) => {
    let _data;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "search", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(term.term)
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            _data = data;
        }).catch((error) => {
            _data = null;
        });

        return _data;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};


export const populateQuestions = async () => {
    try {
        const url = process.env.REACT_APP_BKF_API_BASEURL + "questions";
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};
export const populateMappings = async (ResourceId) => {
    try {
        const url = process.env.REACT_APP_BKF_API_BASEURL + "mapping/" + ResourceId;
        let response = await fetch(url, {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });
        response = await response.json();
        return response;
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const updateResourceMappings = async (mapping) => {
    let submitted = false;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "mapping", {
            method: "PUT",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(mapping),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            submitted = data;
        }).catch((error) => {

        });

        return submitted;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const loginUser = async (creds) => {
    let _data;
    try {
        await fetch(process.env.REACT_APP_BKF_API_BASEURL + "login", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(creds)
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return response.json();
        }).then((data) => {
            _data = data;
        }).catch((error) => {
            _data = null;
        });

        return _data;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};
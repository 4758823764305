import Button from "react-bootstrap/Button";
import "./button.css";

export const OrderBtn = (props) => {

    let setIcon = "";
    let arrow = false;
    let btnStyle = props.btnStyle;

    if (props.sort && props.sort !== "none") {
        
        if (props.sort === props.name) {
            btnStyle = "btnActive";
            setIcon = props.order === "ascending" ? "gcicon-up-open" : "gcicon-down-open";
            arrow = true;
        }
    }

    return (
        <Button
            type={props.type}
            onClick={props.onClick}
            className={btnStyle}
            disabled={props.disabled}
            name={props.name}
            style={{ wordWrap: "break-word" }} 
        >
            {props.text} {arrow === true ? <span className={setIcon}></span> : null}
        </Button>
    );
};
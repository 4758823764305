import React, { useState, useEffect, useRef } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Loader } from "../../../components/loader/loader";
//import { Error } from "../../components/error/error";
import Styles from "./resource.module.css";
import Btn from "../../../components/Button/Button";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { populateResorcesAll } from "../../../utils";
import { ResourceForm } from "./resourceForm";
import { ResourceSearch } from "./resourceSearch";
import { SortResults } from '../../../components/sortBy/sort';
import { OrderBtn } from "../../../components/Button/orderButton";


export const Resource = () => {

    const results = useRef(null);
    const [displayItems, setDisplayItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedResource, setSelectedResource] = useState(0);
    const [reloadResource, setReloadResource] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [sort, setSort] = useState("none");
    const ASC = 'ascending';
    const DSC = 'descending';
    const [order, setOrder] = useState(ASC);

    const getData = async () => {
        setIsLoading(true);
        let data = [];

        if (Object.keys(allItems).length !== 0) data = allItems;
        else {
            data = await populateResorcesAll();
            setAllItems(data);
        }
        if (data && data.length) {
            if (sort !== "none") setDisplayItems(SortResults(data, order, sort))
            else setDisplayItems(data);           
        }
        setIsLoading(false);
        setReloadResource(false)
    }

    useEffect(() => {
        if (reloadResource === true) {
            getData(); setSelectedResource(0);
        }
    }, [reloadResource]);

    function setResourceOrder(field) {
        let _order = order;
        if (field === sort) {
            _order = _order === ASC ? DSC : ASC;
        } else {
            _order = ASC;
        }
        setOrder(_order);
        setSort(field);
        setDisplayItems(SortResults(displayItems, _order, field))
    }

    const scrollToSection = (elementRef) => {
        //let offsetTop = showForm ? elementRef.current.offsetTop - 500 : elementRef.current.offsetTop - 100
        let offsetTop = 0;
        window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
        });
    };

    return (
        <div className={Styles.pageContainer}>
            <div className={Styles.SearchContainer}>
                {!isLoading &&
                    <ResourceSearch
                    setReloadResource={setReloadResource}
                    scrollToSection={scrollToSection}
                    results={results}
                    allItems={allItems}
                    setDisplayItems={setDisplayItems}
                    setShowForm={setShowForm}
                    setIsLoading={setIsLoading}
                    setSort={setSort }
                    />
                }
            </div>
            <div id="form" className={Styles.InputContainer}>
                {showForm && <ResourceForm id={selectedResource} setReloadResource={setReloadResource} setSelectedResource={setSelectedResource} />}
            </div>
            <div id="results" ref={results} className={Styles.Results}>
                {isLoading ? <Loader page="Resources" /> :
                    displayItems?.length === 0 ?
                        <div>No Resources Found</div> :
                        <>
                            <div className="mb-2">
                                Sort Resources
                                <ButtonGroup aria-label="Resource Order">
                                    <OrderBtn onClick={() => setResourceOrder("Active")}
                                        name="Active"
                                        text={"Active"}
                                        btnStyle="btnSecondary"
                                        order={order}
                                        sort={sort}
                                    />
                                    <OrderBtn onClick={() => setResourceOrder("Id")}
                                        name="Id"
                                        text={"Latest"}
                                        btnStyle="btnSecondary"
                                        order={order}
                                        sort={sort}
                                    />
                                    <OrderBtn onClick={() => setResourceOrder("ResourceName")}
                                        name="ResourceName"
                                        text={"Resource Name"}
                                        btnStyle="btnSecondary"
                                        order={order}
                                        sort={sort}
                                    />
                                    <OrderBtn onClick={() => setResourceOrder("ResourceType")}
                                        name="ResourceType"
                                        text={"Type"}
                                        btnStyle="btnSecondary"
                                        order={order}
                                        sort={sort}
                                    />
                                    <OrderBtn onClick={() => setResourceOrder("Provider")}
                                        name="Provider"
                                        text={"Provider"}
                                        btnStyle="btnSecondary"
                                        order={order}
                                        sort={sort}
                                    />
                                </ButtonGroup>
                            </div>
                            <h2>{`${displayItems.length} Resources`}</h2>
                           
                            {displayItems.map((item, idx) => (

                                <div className={`${Styles.ResourceBox} mb-3`} key={idx}>
                                    <Row>
                                        <Col sm="1"><h2 style={{textAlign:"center", marginTop:"10px"} }>{item.ResourceId}</h2></Col>
                                        <Col sm="10">
                                            <Row>
                                                <Col sm="3">
                                                    Active: <b> {item.Active === true? "True": "False"}</b>
                                                </Col>
                                                <Col sm="6">
                                                    Type: <b> {item.ResourceType}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <h3 as={Col }>{item.ResourceName}</h3>
                                            </Row>
                                            <Row>
                                                <h4 as={Col }>{item.Provider}</h4>
                                            </Row>
                                        </Col>
                                        <Col sm="1">
                                            <Btn btnStyle="btnAdminSelect" text="Select" onClick={() => { setSelectedResource(item.ResourceId); setShowForm(true); document.getElementById("form").scroll(0, 0); }} />
                                        </Col>
                                    </Row> 
                                </div>

                            ))}
                        </>
                }

            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Btn from "../../components/Button/Button";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { populateQuestions, populateMappings, updateResourceMappings } from "../../utils";
import Accordion from 'react-bootstrap/Accordion';

export const QuestionModal = (props) => {

    const [disable, setDisable] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [mappings, setMappings] = useState([]);
    
    const getQuestions = async () => {
        const quests = await populateQuestions();
        if (quests) { setQuestions(quests); }
    }

    const getMappings = async (id) => {
        const maps = await populateMappings(id);
        if (maps) { setMappings(maps);}
    }

    useEffect(() => {
        getQuestions();
    },[]);

    useEffect(() => {
        getMappings(props.resourceId);
    }, [props.resourceId]);

    const handleClose = () => {
        props.handleClose();
    };


    const postData = async (mapping) => {
        setDisable(true);
        const data = await updateResourceMappings(mapping);
        return data;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const maps = {
            resourceId: props.resourceId,
            questions: mappings
        };
        postData(maps).then((res) => { setDisable(false); handleClose() });  
    };

    const HandleSelectAll = (ids, event) => {
        //event.preventDefault();
        const checked = event.target.checked;
        const newids = ids.map((item) => { return item.QuestionOptionId });
        if (checked) {
            let remain = newids.filter(o1 => !mappings.some(o2 => o1 === o2));
            for (let r = 0; r < remain.length; r++) {
                setMappings((values) => ([...values, remain[r]]));
            }
        } else {
            let maps = mappings.filter(m => !newids.includes(m));
            setMappings(maps);
        }
        return checked;
        
    }

    const HandleselectAllChecked=(ids) => {
        const newids = ids.map((item) => { return item.QuestionOptionId })
        let maps = mappings;
        let ret = false;
        ret = maps ? newids.every(m => maps.includes(m)) : false;
        return ret;
    }

    const handleChange = (id, e) => {
        const checked = e.target.checked;

        if (checked)
            setMappings((values) => ([ ...values, id ]));
        else {
            let maps = mappings.filter((m) => m !== id)
            setMappings(maps);
        }
    }

    function renderHtml(output) {
        let ret = output;
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: ret } });
    }

    return (
        <Modal show={props.show} onHide={handleClose} centered scrollable={true} size="lg" >
            <Modal.Header closeButton>
                <Modal.Title>Question Association</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <Form>
                    <Form.Control type="hidden"
                        value={props.resourceId}
                    />
                    {questions.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="1">
                            {questions.map((q, idx) => (

                                <Accordion.Item eventKey={idx} key={q.QuestionId}>
                                    <Accordion.Header>{q.Title}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col sm="9">&nbsp;</Col>
                                            <Col sm="3">
                                                <Form.Group controlId={`resourceForm.All${idx}`}>
                                                    <Form.Check
                                                        label={<label>Select All</label>}
                                                        type="switch"
                                                        defaultChecked={HandleselectAllChecked(q.QuestionOptions) }
                                                        onChange={(e) => { HandleSelectAll(q.QuestionOptions, e) }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {q.QuestionOptions.map((o, idx) => (

                                            <Form.Group controlId={`resourceForm.option${idx}`} key={o.QuestionOptionId}>
                                                <Form.Check
                                                    label={<label>{renderHtml(o.Option)}</label>}
                                                    type="checkbox"
                                                    checked={mappings.includes( o.QuestionOptionId ) }
                                                    onChange={(e) => { handleChange(o.QuestionOptionId, e) }}

                                                />
                                            </Form.Group>

                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                        </>
                        : ''}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Btn
                    text={"Submit"}
                    btnStyle="btnActive"
                    disabled={disable}
                    onClick={handleSubmit}
                />
            </Modal.Footer>
        </Modal>
    );
}
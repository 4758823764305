import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Styles from "./filterby.module.css";
import  "./filterby.css";
import Btn from "../../components/Button/Button";
import { Questions } from "../../components/questionRender/accordianQuestions";
import { postQuestionData } from "../../utils";

const FilterBy = ({ data, setSearchItems, setShowResults, setIsLoading, reset, setReset, answers, setAnswers, setChosenQuestions, setSort, setOrder}) => {

    const [btnStyle, setBtnStyle] = useState("btnDisabled");
    const [isFunding, setIsFunding] = useState(false);
    const [isResources, setIsResources] = useState(false);
    const [disable, setDisable] = useState(true);

    const intialvals = {
        One: null,
        Two: null,
        Three: null,
        Four: [],
        Five: [],
        Six: null,
        Seven: null,
        Eight: [],
        Nine: [],
        Ten: [],
        Eleven: []
    };

    const resetFilter = () => {
        setInputs(intialvals);
        setAnswers([]);
        setSearchItems([])
        setShowResults(false);
        setIsFunding(false);
        setIsResources(false);
        setReset(false);
        setSort("none");
        setOrder("ascending");
        document.getElementById("formQuestions").reset();
    }

    useEffect(() => {
        if (reset === true) {
            resetFilter();
            
        }
    }, [reset]);

    const [inputs, setInputs] = useState(intialvals);

    const AllSelected = async (input) => {
        const selectedInputs = input;
        let AllSelected = [];

        if(isResources)
            AllSelected = Object.entries(selectedInputs).filter((i) => i[0] === "One" || i[0] === "Two" || i[0] === "Four" || i[0] === "Eight").map(([key, value, idx]) => {
                return (value === null) ? false : value.length === 0 ? false : true;
            });
        else if (isFunding)
            //let t = Object.entries(selectedInputs).filter((i) => i[0] !== "Eight");
            AllSelected = Object.entries(selectedInputs).filter((i) => i[0] !== "Eight").map(([key, value, idx]) => {
                return (value === null) ? false : value.length === 0 ? false : true;
            });
        else
            AllSelected = Object.entries(selectedInputs).slice(0, 8).map(([key, value, idx]) => {
                return (value === null) ? false : value.length === 0 ? false : true;
            });

        return !AllSelected.includes(false);
    }

    const ids = {
        One: [1, 2, 89],
        Two: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        Three: [14, 15, 16, 17, 18, 19],
        Four: [],
        Five: [36, 37, 38, 39, 40],
        Six: [41, 42, 43],
        Seven: [44, 45, 46, 47, 48, 49, 50],
        Eight: [51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,84,85],
        Nine: [],
        Ten: [70, 71, 72, 73, 74],
        Eleven: [75, 76, 77, 78, 79, 80, 81, 82, 83,86,87]
    }

    const handleChange = (event) => {
        const multiple = ['Four','Eight','Nine','Ten','Eleven']
        const name = event.target.name;
        const value = parseInt(event.target.value);
        const id = event.target.id;
       
        if (!isNaN(value))
        {
            let ans = answers;
            if ([1, 2, 89].includes(value)) {
                setIsFunding((value === 2));
                setIsResources((value === 89));
                if (value === 2) {
                    
                    setInputs((values) => ({ ...values, 'Eight':[]}));
                    let eightArr = ids['Eight'];
                    ans = ans.filter((a) => !eightArr.includes(a.value));
                }
                if (value === 89) {
                    setInputs((values) => ({ ...values, 'Three': -1, 'Five': -1, 'Six': -1, 'Seven': -1}));
                }
            }
            
            if (!multiple.includes(name)) {
                setInputs((values) => ({ ...values, [name]: value }));
            }
            else { 
                if (inputs[name].includes(value))
                    setInputs((values) => ({ ...values, [name]: inputs[name].filter(v => v !== value)}));
                else
                    setInputs((values) => ({ ...values, [name]: [...inputs[name], value] }));
            }
            const vals = {
                id,
                value
            }
            if (!ans.find(v => v.id === id)) {
                let arr = ids[name];
                if (!multiple.includes(name)) {
                    if (arr.includes(value)) {
                        ans = ans.filter((a) => !arr.includes(a.value));
                    }                  
                }
                ans = ans.concat(vals);
            } else {
                if (multiple.includes(name)) {
                    ans = ans.filter((a) => a.value !== value);
                }
            }
            
            setAnswers(ans);
        }
    };

    useEffect(() => {
        AllSelected(inputs).then(function (res) {
            if (res === true) {
                setBtnStyle("btnActive");
                setDisable(false);
            }
            else {
                setBtnStyle("btnDisabled");
                setDisable(true);
            }
        });

    }, [inputs]);


    const postData = async (selected) => {    
        setIsLoading(true);
        setSearchItems([]);
        setShowResults(false);
        setBtnStyle("btnDisabled");
        setDisable(true);

        const data = await postQuestionData(selected);
        if (data) {
            setShowResults(true);
            setSearchItems(data);
            setChosenQuestions(selected)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postData(inputs).then(() => { setIsLoading(false); setBtnStyle("btnActive"); setDisable(false); setSort("none"); setOrder("ascending"); window.scrollTo(0, 0); });
    }

    return (
        <div className={Styles.filterContainer}>
            <Form name='formQuestions' id='formQuestions' onSubmit={(event) => handleSubmit(event)}>

                <Questions data={data} handleChange={handleChange} isFunding={isFunding} isResources={isResources} reset={reset} />
                
                <div className={Styles.formBtnContainer}>
                    <Btn
                        text={"Find resources"}
                        btnStyle={btnStyle}
                        type='submit'
                        disabled={disable}
                    />
                </div>
            </Form>
        </div>
    );
}


export default FilterBy;

export const SortResults = (data, order, sort) => {

    let resources=data;
    const ASC = 'ascending';
    const DSC = 'descending'; 

    function sortByName(a, b) {
        const diff = a.ResourceName.toLowerCase().localeCompare(b.ResourceName.toLowerCase());
        if (order === ASC) {
            return diff;
        }
        return -1 * diff;
    }

    function sortByType(a, b) {
        const diff = a.ResourceType.toLowerCase().localeCompare(b.ResourceType.toLowerCase());
        if (order === ASC) {
            return diff;
        }
        return -1 * diff;
    }

    function sortByProv(a, b) {
        const diff = a.Provider.toLowerCase().localeCompare(b.Provider.toLowerCase());
        if (order === ASC) {
            return diff;
        }
        return -1 * diff;
    }
    function sortByActive(a, b) {
        if (order === ASC) {
            return (a.Active === b.Active) ? 0 : a.Active ? -1 : 1;
        }
        return (a.Active === b.Active) ? 0 : a.Active ? 1 : -1;
    }

    function sortById(a, b) {
        const diff = a.ResourceId-b.ResourceId;
        if (order === ASC) {
            return - 1 * diff;
        }
        return diff;
    }

    let sortChoice = sort;
    switch (sortChoice) {
        case 'Active':
            resources.sort(sortByActive);
            break;
        case 'Id':
            resources.sort(sortById);
            break;
        case 'ResourceName':
            resources.sort(sortByName);
            break;
        case 'ResourceType':
            resources.sort(sortByType);
            break;
        case 'Provider':
            resources.sort(sortByProv);
            break;
        default: break;

    }

    return resources;

}
import React, { useState, useEffect } from "react";
import Styles from "./accordianQuestions.module.css";
import "./accordianQuestions.css";
import Accordion from 'react-bootstrap/Accordion';

export const Questions = ({ data, handleChange, isFunding, isResources, reset }) => {
    const [questions, setQuestions] = useState([]);
    const [expandedItem, setExpandedItem] = useState("0");

    useEffect(() => {
        if (isFunding === true) {
            setQuestions(data.filter((v) => v.id !== 8));
        } else if (isResources === true) {
            setQuestions(data.filter((v) => v.id === 1 || v.id === 2 || v.id === 4 || v.id === 8));           
        } else {
            setQuestions(data.slice(0, 8));
        }
    }, [data,isFunding,isResources,setQuestions]);

    useEffect(() => {
        if (reset === true) {
            setExpandedItem("0");
        }
    }, [reset]);

    function renderHtml(output) {
        let ret = output;
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: ret } });
    }

    function HandleClick(event) {
        const multiple = ['Four', 'Eight', 'Nine', 'Ten', 'Eleven'];
        if (!multiple.includes(event.target.name))
            setExpandedItem((Number(expandedItem) + 1).toString());
        handleChange(event);
    }

    return (
        <>
            <Accordion
                defaultActiveKey="0"
                activeKey={expandedItem}
                onSelect={(e) => {
                    if (e !== null) setExpandedItem(e)
                    else setExpandedItem('-1');
                } }
            >

                {questions.map((item, i) => (
                    <Accordion.Item eventKey={i.toString()} key={item.id}>
                        <Accordion.Header>{renderHtml(item.question)}</Accordion.Header>
                        <Accordion.Body>
                            {item.options.map((sub, idx) =>
                                <label className={Styles.container} key={sub.id}>
                                    <input onClick={(event) => HandleClick(event)}
                                        type={item.type}
                                        id={sub.info}
                                        name={item.name}
                                        value={sub.value}
                                    />
                                    <span className={Styles.checkmark} ></span>
                                    {renderHtml(sub.info)}
                                </label>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}

            </Accordion>
        </>
    );

}
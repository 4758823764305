import ResetFiltersStyles from "./resetFilters.module.css";
import Reset from "../../assets/images/reset.svg";

const ResetFilters = (props) => {
    return (
        <div className={ResetFiltersStyles.resetWrapper} onClick={() => props.handleReset()}>
            <img className={ResetFiltersStyles.resetIcon} src={Reset} alt={"Reset Filters"} />
            <p>{props.text}</p>
        </div>
    );
}

export default ResetFilters;
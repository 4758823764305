import logo from "../../../src/assets/images/GM_BGH_Master_Logo_Navy_RGB.png";
import NavStyles from "./navbar.module.css";

const Navbar = () => {
    return (
        <nav className={NavStyles.navbar}>
            <div>
                <img src={logo} alt="Business Growth Hub"></img>
            </div>
            {/* <h1>The Dojo Blog</h1> */}
            <div className={NavStyles.navtitle}>
                {/* <a href="/">Home</a> */}
                <h3>Business Support & Funding Finder</h3>
            </div>
        </nav>
    );
}

export default Navbar;
import React, { useState, useEffect, useRef } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from "react-bootstrap/Form";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Btn from "../../../components/Button/Button";
import { postSearchResource, populateQuestions } from "../../../utils";
import "./resource.css";
//import Styles from "./resource.module.css";

export const ResourceSearch = ({setReloadResource, scrollToSection, results, allItems, setDisplayItems, setShowForm, setIsLoading, setSort }) => {
    const formRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState({});
    const [funding, setFunding] = useState([]);
    const [sector, setSector] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [resourceTypes, setResourceTypes] = useState([]);
    const [filter, setFilter] = useState('');

    const getQuestions = async () => {
        const quests = await populateQuestions();
        if (quests) {
            setFunding(quests.filter((q) => q.QuestionId === 9)[0].QuestionOptions);
            setSector(quests.filter((q) => q.QuestionId === 4)[0].QuestionOptions);
            setPriorities(quests.filter((q) => q.QuestionId === 8)[0].QuestionOptions);
            if (allItems)
                setResourceTypes(Array.from(new Set(allItems.map((item) => item.ResourceType))));
        }
    }

    useEffect(() => {
        getQuestions();
    }, []);

    const handleChange = (field, e) => {
        const value = e.target.value;
        if (field !== 'Active') {
            setSearchTerm((values) => ({ ...values, [field]: value }));
        }
    }
    const postSearch = async () => {
        if (Object.keys(searchTerm).length !== 0) {
            const data = searchTerm;
            const resources = await postSearchResource(data);
            return resources;
        } else return null;
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        setShowForm(false); setIsLoading(true);
        postSearch().then((res) => {
            if (res) {
                setDisplayItems(res); setIsLoading(false); scrollToSection(results); setSort("none");
            } else {
                setIsLoading(false); setShowForm(true);
            }
        })
    }

    const handleReset = (event) => {
        event.preventDefault();
        setReloadResource(true)
        setFilter('');
        setShowForm(true);
        setSearchTerm({});
        formRef.current.reset();
    }

    const handleFilter = (key, event, btn) => {
        const clonedItems = Array.from(allItems);
        let filteredItems = [];
        if (!isNaN(+key))
            filteredItems = clonedItems.filter((item) => item.QuestionOptions.some(option => option.QuestionOptionId === Number(key)));
        else
            filteredItems = clonedItems.filter((item) => item.ResourceType === key);

        setDisplayItems(filteredItems);
        setFilter(`${btn} : ${event.target.text}`);
        scrollToSection(results);
        setShowForm(false);
        setSort("none");
    }

    return (
        <>
            <Form noValidate ref={formRef}>
                <Row className="mb-0">
                    <Form.Group as={Col} sm="9" className="mb-0" controlId="resourceForm.ResourceSearch">
                        <FloatingLabel controlId="floatingName" label="Wild card search (ID, Name, Provider, Resource & Description)">
                            <Form.Control placeholder="Wild card search (ID, Name, Provider, Resource & Description)"
                                onChange={(e) => { handleChange("term", e) }}

                                autoFocus
                                required
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <Col sm="3">
                        <ButtonGroup>
                            <Btn btnStyle="btnAdminSelect" text="Search" onClick={(event) => handleSubmit(event)} />
                            <Btn btnStyle="btnReset" text="Reset" onClick={(event) => handleReset(event)} />
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col sm="1" style={{ verticalAlign: "text-top" }}><h4>Filter</h4></Col>
                    <ButtonGroup as={Col} sm="12" md="7" className="flex-wrap">
                        <DropdownButton id="resourceForm.fundingOption" title="Funding Type" onSelect={(evkey, ev) => handleFilter(evkey, ev, "Type") }>
                            {funding.map((f, idx) => (
                                <Dropdown.Item key={idx} eventKey={f.QuestionOptionId}>{f.Option}</Dropdown.Item>

                            ))}
                        </DropdownButton>
                        <DropdownButton variant="secondary" id="resourceForm.sectorOption" title="Sector" style={{ paddingLeft: "5px", paddingRight: "5px" }} onSelect={(evkey, ev) => handleFilter(evkey, ev, "Sector")}>
                            {sector.map((f, idx) => (
                                <Dropdown.Item key={idx} eventKey={f.QuestionOptionId}>{f.Option}</Dropdown.Item>

                            ))}
                        </DropdownButton>
                        <DropdownButton id="resourceForm.prioritiesOption" title="Key Priorities" onSelect={(evkey, ev) => handleFilter(evkey, ev, "Priorities")}>
                            {priorities.map((f, idx) => (
                                <Dropdown.Item key={idx} eventKey={f.QuestionOptionId}>{f.Option}</Dropdown.Item>

                            ))}
                        </DropdownButton>
                        <DropdownButton variant="secondary" id="resourceForm.Type" title="Resource Type" style={{ paddingLeft: "5px" }} onSelect={(evkey, ev) => handleFilter(evkey, ev, "Resource Type")}>
                            {resourceTypes.map((t, idx) => (
                                <Dropdown.Item key={idx} eventKey={t}>{t}</Dropdown.Item>

                            ))}
                        </DropdownButton>
                    </ButtonGroup>
                    <Col md={{ span: 4 }}><span style={{ fontSize: "13px", fontWeight: "bold" }}>{filter}</span></Col>
                </Row>
            </Form>
        </>

    )
};
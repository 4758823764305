import React from "react";
import FilterTabStyles from "./filterTab.module.css";

const FilterTab = (props) => {
    function renderHtml(output) {
        let ret = output;
        return React.createElement("p", { dangerouslySetInnerHTML: { __html: ret } });
    }
    return (
        <div className={FilterTabStyles.tabContainer}>
            {renderHtml(props.text)}
        </div>
    );
}

export default FilterTab;
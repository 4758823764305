import React from "react";
//import { NavMenu } from "../NavMenu/NavMenu";
//import { Footer } from "../Footer/Footer";
import Navbar from '../NavBar/NavBar';
//import Home from './pages/Home/Home';
import Footer from "../Footer/Footer";

export const Layout = (props) => {
    return (
        <div className="App">
            <Navbar />
            {props.children}
            <Footer />
        </div>
    );
};

import React, { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Btn from "../../../components/Button/Button";
import { Alerts } from "../../../components/alerts/alerts";
import { Loader } from "../../../components/loader/loader";
import { populateResourcesSingle, updateResource } from "../../../utils";
import { QuestionModal } from "../../../components/questionModal/questionModal";
import { getParsedDate } from "../../../helpers";
import Styles from "./resource.module.css";

export const ResourceForm = ({ id, setReloadResource, setSelectedResource }) => {
    const formRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resourceData, setResourceData] = useState({});
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ResourceId, setResourceId] = useState(-1);
    const [disable, setDisable] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getResource = async (Id) => {
        setSubmitted(false);
        setIsLoading(true);
        if (Id > 0) {
            const data = await populateResourcesSingle(Id);
            if (data) { setResourceData(data); setResourceId(Id); }
        }
        setIsLoading(false);
    }

    const postResource = async () => {
        setIsLoading(true);
        const data = resourceData;

        const updated = await updateResource(data);
        
        setIsLoading(false);
        return updated;
    }

    const handleValidation = () => {
        let formIsValid = true;
        let resourceNameValid = true, resourceTypeValid = true, providerValid = true, linkValid = true, headlineValid = true, summaryValid = true, descriptionValid = true;

        //ResourceName
        resourceNameValid = validateField("ResourceName", resourceData.ResourceName);
        //ResourceType
        resourceTypeValid = validateField("ResourceType", resourceData.ResourceType);
        //Provider
        providerValid = validateField("Provider", resourceData.Provider);
        //Headline
        headlineValid = validateField("Headline", resourceData.Headline);
        //Summary
        summaryValid = validateField("Summary", resourceData.Summary);
        //Description
        descriptionValid = validateField("Description", resourceData.Description);
        //Link
        linkValid = validateField("Link", resourceData.Link);

        formIsValid = (resourceNameValid && resourceTypeValid && providerValid && linkValid && headlineValid && summaryValid && descriptionValid);
        setValidated(formIsValid);
        return formIsValid;
    };

    const validateField = (field, val) => {
        let isValid = true, errMes = '';

        switch (field) {
            case 'ResourceName':
            case 'Provider':
            case 'Headline':
                if (!val || val.length === 0) { errMes = 'Please enter a value'; isValid = false; }
                else {
                    isValid = val.match(/^[a-zA-Z0-9!@#$%^&*()_+\-= [\]{};':"\\|,.<>/?]{3,256}$/);
                    errMes = isValid ? '' : ' Please enter valid value';
                }
                break;
            case 'ResourceType':
                if (!val || val.length === 0) { errMes = 'Please enter a type of resource'; isValid = false; }
                else {
                    isValid = val.match(/^[\w .]{6,50}$/);
                    errMes = isValid ? '' : ' Please enter valid type of resource';
                }
                break;
            case 'Summary':
                if (!val || val.length === 0) { errMes = 'Please enter a summary'; isValid = false; }
                break;
            case 'Description':
                if (!val || val.length === 0) { errMes = 'Please enter a Description'; isValid = false; }
                break;
            case 'Link':
                if (!val || val.length === 0) { errMes = 'Please enter a value'; isValid = false; }
                else {
                    isValid = val.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/);
                    errMes = isValid ? '' : ' Please enter valid value';
                }
                break;
 
            default:
                break;
        }
        if (errMes !== '') setErrors((errs) => ({ ...errs, [field]: errMes }));

        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (handleValidation()) {
            setDisable(true);
            postResource().then((res) => {
                setDisable(false);
                setReloadResource(true);
                setSubmitted(true)
                setResourceId(res);
            });
        }
    };

    const handleChange = (field, e) => {
        const value = e.target.value;

        if (field !== 'Active') {
            setErrors((errs) => ({ ...errs, [field]: '' }));
            setResourceData((values) => ({ ...values, [field]: value }));
            validateField(field, value);
        }
        else {
            setResourceData((values) => ({ ...values, [field]: e.target.checked }));
            //validateField(field, e.target.checked);
        }
    }

    const handleClear = (ev) => {
        ev.preventDefault();
        setSelectedResource(0);
        setResourceData({});
        setResourceId(0);
        setReloadResource(true);
    }

    useEffect(() => {
        getResource(id);
    }, [id]);

    return (
        <>
            {isLoading ? <Loader page="Resource admin" /> : 
                submitted ? 
                    ResourceId === 0 ? <Alerts text="Please contact someone" header="Resource failed to apply" variant="danger" func={setSubmitted }/> :
                    <Alerts text="Please close this to continue" header="Resource Added\Updated" variant="success" func={setSubmitted }/>
                :
                <Form noValidate ref={formRef} validated={validated}>
                    <Form.Control type="hidden"
                        value={resourceData["ResourceId"] || 0}
                    />
                    <Row>
                        <Form.Group as={Col} sm="1" controlId="resourceForm.ResourceId">
                            <Row>
                                <Form.Label column sm="2">
                                    ID
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control plaintext readOnly value={resourceData["ResourceId"] || 0} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="resourceForm.ResourceCreated">
                            <Row>
                                <Form.Label column sm="3">
                                    Created
                                </Form.Label>
                                <Col sm="9">
                                        <Form.Control plaintext readOnly value={resourceData["CreatedOn"] ? getParsedDate(resourceData["CreatedOn"]) : ''} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="resourceForm.ResourceUpdated">
                            <Row>
                                <Form.Label column sm="3">
                                    Updated
                                </Form.Label>
                                <Col sm="9">
                                        <Form.Control plaintext readOnly value={resourceData["UpdateOn"] ? getParsedDate(resourceData["UpdateOn"]) : ''} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} sm="3" controlId="resourceForm.ExpiryDate">
                        
                            <Form.Control
                                onChange={(e) => { handleChange("ExpiresBy", e) }}
                                    value={resourceData["ExpiresBy"] ? new Date(resourceData["ExpiresBy"]).toISOString().split('T')[0] : ''}
                                isValid={!errors.ExpiresBy && !!resourceData.ExpiresBy && resourceData.ExpiresBy !== ''}
                                isInvalid={!!errors.ExpiresBy && errors.ExpiresBy !== ''}
                                type="date"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["ResourceName"]}
                            </Form.Control.Feedback>

                        </Form.Group>
                        <Form.Group as={Col} sm="2" controlId="resourceForm.Active">
                            <Form.Check
                                label={<label>Active</label>}
                                type="checkbox"
                                checked={resourceData["Active"] === false ? false : true}
                                onChange={(e) => { handleChange("Active", e) }}

                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-1" controlId="resourceForm.ResourceName">
                        <FloatingLabel controlId="floatingName" label="Resource Name (256 chars max)">
                            <Form.Control placeholder="Resource Name (256 chars max)"
                                onChange={(e) => { handleChange("ResourceName", e) }}
                                value={resourceData["ResourceName"] || ''}
                                isValid={!errors.ResourceName && !!resourceData.ResourceName && resourceData.ResourceName !== ''}
                                isInvalid={!!errors.ResourceName && errors.ResourceName !== ''}
                                autoFocus
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["ResourceName"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} className="mb-1" controlId="resourceForm.Provider">
                            <FloatingLabel controlId="floatingProvider" label="Provider">
                                <Form.Control placeholder="Provider"
                                    onChange={(e) => { handleChange("Provider", e) }}
                                    value={resourceData["Provider"] || ''}
                                    isValid={!errors.Provider && !!resourceData.Provider && resourceData.Provider !== ''}
                                    isInvalid={!!errors.Provider && errors.Provider !== ''}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors["Provider"]}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group as={Col} className="mb-1" controlId="resourceForm.Type">
                            <FloatingLabel controlId="floatingType" label="Resource Type">
                                <Form.Control placeholder="Resource Type"
                                    onChange={(e) => { handleChange("ResourceType", e) }}
                                    value={resourceData["ResourceType"] || ''}
                                    isValid={!errors.ResourceType && !!resourceData.ResourceType && resourceData.ResourceType !== ''}
                                    isInvalid={!!errors.ResourceType && errors.ResourceType !== ''}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors["ResourceType"]}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-1" controlId="resourceForm.Headline">
                        <FloatingLabel controlId="floatingHeadLine" label="Headline">
                            <Form.Control placeholder="Headline"
                                value={resourceData["Headline"] || ''}
                                onChange={(e) => { handleChange("Headline", e) }}
                                isValid={!errors.Headline && !!resourceData.Headline && resourceData.Headline !== ''}
                                isInvalid={!!errors.Headline && errors.Headline !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["Headline"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="resourceForm.ResourceSummary">
                        <FloatingLabel controlId="floatingSummary" label="Summary">

                            <Form.Control placeholder="Summary" as="textarea" rows="3"
                                value={resourceData["Summary"] || ''}
                                onChange={(e) => { handleChange("Summary", e) }}
                                isValid={!errors.Summary && !!resourceData.Summary && resourceData.Summary !== ''}
                                isInvalid={!!errors.Summary && errors.Summary !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["Summary"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="resourceForm.Description">
                        <FloatingLabel controlId="floatingDescription" label="Description">

                            <Form.Control placeholder="Description" as="textarea" rows="5"
                                value={resourceData["Description"] || ''}
                                onChange={(e) => { handleChange("Description", e) }}
                                isValid={!errors.Description && !!resourceData.Description && resourceData.Description !== ''}
                                isInvalid={!!errors.Description && errors.Description !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["Description"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="resourceForm.Link">
                        <FloatingLabel controlId="floatingDescription" label="Link - Url to external site">

                            <Form.Control placeholder="Link - Url to external site"
                                value={resourceData["Link"] || ''}
                                onChange={(e) => { handleChange("Link", e) }}
                                isValid={!errors.Link && !!resourceData.Link && resourceData.Link !== ''}
                                isInvalid={!!errors.Link && errors.Link !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["Link"]}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                        <div className={Styles.buttonContainer }>
                            <Btn disabled={disable} btnStyle="btnAdminSelect" text="Add \ Update" onClick={(event) => handleSubmit(event)} />
                            
                            {(ResourceId > 0) &&
                                <>
                                <Btn btnStyle="btnAdminSelect" text="Clear" onClick={(event) => handleClear(event)} />
                                <QuestionModal show={show} handleClose={handleClose} resourceId={ResourceId }/>
                                <Btn disabled={disable} btnStyle="btnAdminSelect" text="Associate" onClick={(event) => { event.preventDefault(); handleShow(!show) }} />
                                </>
                            }
                    </div>
                </Form>
            }
        </>
    )
}
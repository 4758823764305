import React, { useState, useEffect } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import HomeStyles from "./home.module.css";
import FilterBy from "../../components/FilterBy/FilterBy";
import { filterList } from '../../components/FilterBy/filterList';
import Btn from "../../components/Button/Button";
import { OrderBtn } from "../../components/Button/orderButton";
import ResetFilters from "../../components/ResetFilters/ResetFilters";
import FilterTab from "../../components/FilterTab/FilterTab";
import ResourceItem from "../../components/ResourceItem/ResourceItem";
//import { motion } from 'framer-motion';
import { Loader } from "../../components/loader/loader";
//import { Error } from "../../components/error/error";
import { SendModal } from '../../components/sendModal/sendModal';
import {SortResults} from '../../components/sortBy/sort';

const Home = () => {

    const [showResults, setShowResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setdata] = useState(null);
    const [searchItems, setSearchItems] = useState([]);
    const [resourceIds, setResourceIds] = useState([]);
    const [chosenQuestions, setChosenQuestions] = useState([]);
    const [reset, setReset] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [sort, setSort] = useState("none");
    const ASC = 'ascending';
    const DSC = 'descending';
    const [order, setOrder] = useState(ASC);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleReset = () => {
        setReset(true);
    }

    useEffect(() => {
        setdata(filterList)
    }, []);

    useEffect(() => {
        if (searchItems && searchItems.length > 0) {
            let results = searchItems.map((item) => { return item.ResourceId })
            setResourceIds(results)
        }
    }, [searchItems]);

    const filterGenerator = (answers) => (answers.map((content, idx) => <FilterTab key={idx} text={content.id} />));

    function setResourceOrder(field) {
        let _order = order;
        if (field === sort) {
            _order = _order === ASC ? DSC : ASC;
        } else {
            _order = ASC;
        }
        setOrder(_order);    
        setSort(field);
        setSearchItems(SortResults(searchItems, _order, field))
    }

    return (
        <div className={HomeStyles.pageContainer}>
            <div className={HomeStyles.container}>
                <div className={HomeStyles.sidebar}>
                    <div className={HomeStyles.sidebarContainer}>
                        {data &&
                            <FilterBy data={filterList}
                                setShowResults={setShowResults}
                                setSearchItems={setSearchItems}
                                setIsLoading={setIsLoading}
                                reset={reset} setReset={setReset}
                                answers={answers} setAnswers={setAnswers}
                                setChosenQuestions={setChosenQuestions}
                                setSort={setSort}
                                setOrder={setOrder }
                            />
                        }
                    </div>
                </div>
                <div className={HomeStyles.infoPanel}>
                    <div className={HomeStyles.infoPanelContainer}>
                        
                        <h3>Helping with your business's support & funding requirements</h3>
                        <p>
                            Designed to help business owners navigate the landscape of publicly backed business support in Greater Manchester.
                        </p>
                        <p>
                            Not only that, but we have recently added in a number of private sector investment funds and angel networks to support you in your funding journey.
                        </p>
                        <p>By answering a few simple questions, we can identify what support and funding is available for your business, based on your individual business needs and goals.</p>
                        <div className={HomeStyles.buttonContainer} >
                            <SendModal show={show} handleClose={handleClose} title="Download Resources" ResourceIds={resourceIds} Answers={chosenQuestions}  />
                            <Btn onClick={showResults ? handleShow : null}
                                text={"Download results as PDF"}
                                btnStyle={showResults ? "btnSecondary" : "btnDisabled"}
                            />
                        </div>

                        <hr className={HomeStyles.line}></hr>

                        <div className={HomeStyles.resetContainer}>
                            <h5 className={HomeStyles.blueText}>
                            { showResults && `There are ${searchItems.length} resources that meet your criteria`}
                            </h5>
                            <div>
                                <ResetFilters handleReset={handleReset} text={"Reset Filters"} />
                            </div>
                        </div>

                        <div className={HomeStyles.filtertabsContainer}>
                            { filterGenerator(answers) }
                        </div>

                        {!showResults && !isLoading &&
                            <>
                                <hr className={HomeStyles.line}></hr>
                                <p className={HomeStyles.disclaimer}>
                                    The information provided is meant as a general guide only rather than advice or assurance. GM Business Growth Hub does not guarantee the accuracy or completeness of this information and professional guidance should be sought on all aspects of business planning. Use of this website is entirely at the risk of the user. Any hyperlinks on this website which are to external resources are not connected to the GM Business Growth Hub or The Growth Company. The Growth Company (or any of the companies in its group) is not responsible for the content within any hyperlinked site to an external resource. We may refer you to other partners or information sources. We are not liable for any services, advice or information received from a referral partner or information source. A referral by us does not constitute an endorsement.
                                </p>
                            </>    
                        }
                    </div>
                </div>
                <div className={HomeStyles.results}>
                    <div className={HomeStyles.resultsContainer}>
                    
                        <div className={HomeStyles.resourceItemContainer}>
                            {isLoading ? <Loader page="Home" /> :
                                searchItems?.length === 0 ?
                                    <div>&nbsp;</div> :
                                    <>

                                        <div>
                                            Sort Resources
                                            <ButtonGroup aria-label="Resource Order">
                                                <OrderBtn onClick={() => setResourceOrder("ResourceName")}
                                                    name="ResourceName"
                                                    text={"Name (A-Z)"}
                                                    btnStyle="btnSecondary"
                                                    order={order}
                                                    sort={sort}
                                                />
                                                <OrderBtn onClick={() => setResourceOrder("ResourceType")}
                                                    name="ResourceType"
                                                    text={"Resource type (A-Z)"}
                                                    btnStyle="btnSecondary"
                                                    order={order}
                                                    sort={sort}
                                                />
                                                <OrderBtn onClick={() => setResourceOrder("Provider")}
                                                    name="Provider"
                                                    text={"Provider (A-Z)"}
                                                    btnStyle="btnSecondary"
                                                    order={order}
                                                    sort={sort}
                                                />
                                            </ButtonGroup>                                        
                                        </div>

                                        {searchItems.map((item, idx) => (

                                            <ResourceItem
                                                key={item.ResourceId}
                                                category={item.Provider}
                                                title={item.ResourceName}
                                                resourceType={item.ResourceType }
                                                text={item.Summary}
                                                readMore={item.Description}
                                                link={item.Link}
                                            />
                                        ))}
                                    </>
                            }
                         </div>
                        {showResults &&
                            <>  
                               
                                <hr className={HomeStyles.line}></hr>
                                <p className={HomeStyles.disclaimer}>
                                The information provided is meant as a general guide only rather than advice or assurance.
                                GM Business Growth Hub does not guarantee the accuracy or completeness of this information and professional guidance should be sought on all aspects of business planning.
                                Use of this website is entirely at the risk of the user. Any hyperlinks on this website which are to external resources are not connected to the GM Business Growth Hub or The Growth Company.
                                The Growth Company (or any of the companies in its group) is not responsible for the content within any hyperlinked site to an external resource.
                                We may refer you to other partners or information sources. We are not liable for any services, advice or information received from a referral partner or information source.
                                A referral by us does not constitute an endorsement.
                                </p>
                                <hr className={HomeStyles.line}></hr>
                                <Btn text="back to the top" onClick={() => window.scrollTo(0, 0)} btnStyle="btnSecondary" />
                            </>
                        }

                    </div>
                </div>
            </div>

        </div>
    );
}

export default Home;

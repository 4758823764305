import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router, Navigate, useLocation } from "react-router-dom";

import useAuth, { AuthProvider } from "./useAuth";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/gcicons.css";

import ScrollToTop from "./utils/ScrollToTop";

import { Layout } from "./components/layout/Layout";
import Home from './pages/Home/Home';
import { Terms } from "./pages/terms/Terms";
import { Accessibility } from "./pages/accessibility/Accessibility";
import { Cookies } from "./pages/cookies/Cookies";
import { Resource } from "./pages/admin/resources/resource";
import Login from './pages/Login/Login';
import { PageNotFound } from "./pages/pageNotFound/PageNotFound";


function RequireAuth({ children }) {
    const { authed } = useAuth();
    const location = useLocation();

    return authed === true ? (
        children
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );  
}

function App() {

    return (
        <>
            <Router>
                <AuthProvider>
                    <ScrollToTop />
                    <Layout>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/cookie-policy" element={<Cookies />} />
                            <Route path="/accessibility-statement" element={<Accessibility />} />
                            <Route path="/terms-and-conditions" element={<Terms />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="*" element={<PageNotFound />} />
                            <Route path="/13c18e691c3546f3af9f6b4b567dbdf3" element={
                                <RequireAuth>
                                    <Resource />
                                </RequireAuth>} />
                        </Routes>
                    </Layout>
                </AuthProvider>
            </Router>
    </>
    );
}

export default App;

import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Btn from "../../components/Button/Button";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useNavigate } from "react-router-dom";
import useAuth from '../../useAuth';
import { Alerts } from "../../components/alerts/alerts";
import './Login.css';

export default function Login() {
    const navigate = useNavigate()
    const { login } = useAuth();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [badLogin, setBadLogin] = useState(false);
    const [disable, setDisable] = useState(false);

    const handleLogin = (ev) => {
        ev.preventDefault();
        if (username !== '' && password !== '') {
            setDisable(true);
            login(username, password).then(() => {
                setBadLogin(false);
                navigate("/13c18e691c3546f3af9f6b4b567dbdf3");
            }).catch((reason) => {
                setBadLogin(true);
            }).finally(() => {
                setDisable(false);
            });
        }
    };

    return (
        <div className="login-wrapper">
            {badLogin && <Alerts text="Ah ah aaah you didn't say the magic word" header="Invalid" variant="danger" func={setBadLogin} /> }
            <h3>Please log in to continue</h3>
            <Form>
                <Form.Group className="mb-1" controlId="username">
                    <FloatingLabel controlId="floatinguserName" label="Username">
                        <Form.Control placeholder="Username"
                            onChange={e => setUserName(e.target.value)}
                            autoFocus
                            required
                        />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-1" controlId="pwd">
                    <FloatingLabel controlId="floatingPwd" label="Password">
                        <Form.Control placeholder="Password"
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            required
                        />
                    </FloatingLabel>
                </Form.Group>

                <div>
                    <Btn
                        text={"Submit"}
                        btnStyle="btnActive"
                        disabled={disable}
                        onClick={(event) => { handleLogin(event) }}
                    />
                </div>
            </Form>
        </div>
    )
}